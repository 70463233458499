// 电脑端入口页

import { useState, useEffect } from 'react'
import RouterConfig from './common/router_config'
import { HashRouter } from 'react-router-dom'
import './styles/common.css'
import './App.css'
import 'antd/dist/antd.css'
import Login from './components/login/index'
import Menu from './components/menu/index'
import { ConfigProvider, BackTop } from 'antd'
import { UpCircleOutlined, PoweroffOutlined, EditOutlined } from '@ant-design/icons';
import { url } from './common/url_config'
import fun from './common/common_fun'
import zhCN from 'antd/es/locale/zh_CN';
import cookie from 'react-cookies'

function App() {
  let [user_id, set_user_id] = useState('')
  let [user_info, set_user_info] = useState({ '角色': '', '账号': '', '姓名': '' })

  useEffect(() => {
    if ((cookie.load('userinfo'))) {
      console.log(cookie.load('userinfo'))
      set_user_info(cookie.load('userinfo'))
      if (cookie.load('userinfo')['id']) {
        set_user_id(cookie.load('userinfo')['id'])
      }
    }
  }, [])

  const log_out = () => {
    let is_confirm = window.confirm('确认退出登陆？')
    if (!is_confirm) {
      return
    }
    fun['post_data'](url + 'admin_logout').then(res => console.log(res))
    cookie.remove('userinfo')
    window.location.href = '/#/'
    window.location.reload()
  }

  if (!user_id) {
    return <Login />
  }

  return (
    <div>
      <BackTop>
        <div style={{ backgroundColor: '#1088e9', color: 'white', opacity: 0.5, width: 40, height: 40, textAlign: 'center', lineHeight: '48px' }} ><UpCircleOutlined style={{ fontSize: 25 }} /></div>
      </BackTop>
      <HashRouter>
        <div style={{ backgroundColor: 'rgba(236, 236, 236, 1)' }}>
          <div style={{ zIndex: 1000, position: 'fixed', top: 0, left: 0, height: 35, width: '100%', backgroundColor: '#008ced', color: '#ffffff', lineHeight: '35px' }}>
            <h3 style={{ float: 'left', marginLeft: 10, color: '#ffffff', }}><b>当前公司：{user_info['姓名']}</b><i style={{ marginLeft: 10, fontSize: 15 }}>欢迎使用安全作业培训系统</i></h3>
            <div onClick={() => {
              log_out()
            }} style={{ float: 'right', cursor: 'pointer', marginRight: 10 }}><PoweroffOutlined /> 退出登录</div>
            <div style={{ float: 'right', marginRight: 10 }}>您好：公司{user_info['角色']} {user_info['账号']} 欢迎使用</div>
          </div>
          <div style={{ width: 140, position: 'fixed', left: 0, top: 35 }}><Menu /></div>
          <div style={{ width: '100%', backgroundColor: 'white', marginTop: 35, overflow: 'auto', paddingBottom: 20 }}>
            <div style={{ width: 'calc(100% - 140px)', float: 'right', overflow: 'hidden' }} className='router'>
              <ConfigProvider locale={zhCN}><RouterConfig /></ConfigProvider>
            </div>
          </div>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;