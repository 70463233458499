import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'

// post请求发送paras返回json
const post_data = (url: string, paras = {}) => {
  let params = {
    ...paras,
    session_key: cookie.load('session_key') || '',
    login_id: cookie.load('login_id') || '',
  }
  return fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).then(res => res.json()).catch(res => ({ status: false, result: '网络错误：' + res }))
}
// 上传大文件
const uploadFile = (obj: any, callback: any) => {
  axios.defaults.withCredentials = true
  let { file, urlUpload, urlMerge, slicingBig = 1024 } = obj
  if (!file) return
  var arrAxios = []
  var slicingSize = 1024 * 1024 * slicingBig;//-----------------------------------------这里可以设置多大内存分成一片现在是1g
  var slicingCount: any = Math.ceil(file.size / slicingSize); // 分片总数
  var ext = file.name.split('.');
  ext = ext[ext.length - 1]; // 获取文件后缀名
  var random = Math.floor(Math.random() * (100 - 1)) + 1;
  var hash = Date.now() + random + file.lastModified; // 文件 hash 实际应用时，hash需要更加复杂，确保唯一性，可以使用uuid
  //创建数组代理来判断完成多久
  var arr = new Proxy(new Array(slicingCount), {
    set(target, key, value, receiver) {
      var prose = 0;
      target.forEach(item => {
        if (item) {
          prose += item
        }
      })
      callback({ name: 'upload', value: (prose / slicingCount).toFixed(2), msg: "上传中" })
      return Reflect.set(target, key, value, receiver);
    },
    get(target, key: any) {
      return target[key];
    }
  })
  for (let i = 0; i < slicingCount; i++) {
    let start = i * slicingSize,
      end = Math.min(file.size, start + slicingSize);
    const form = new FormData();//通过formdata的方式提交
    form.append('file', file.slice(start, end));
    form.append('name', file.name);
    form.append('total', slicingCount);
    form.append('ext', ext);
    form.append('index', String(i));
    form.append('size', file.size);
    form.append('hash', hash);
    arrAxios.push(axios.post(urlUpload, form, {
      onUploadProgress: (progressEvent: any) => {
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
        arr[i] = complete;
      }
    }));//这改成你的分片请求路径
  }
  axios.all(arrAxios).then(res => {
    const data = {
      name: file.name,
      fileId: "2",
      total: slicingCount,
      ext,
      hash
    };
    axios.post(urlMerge, data, {
      onUploadProgress: (progressEvent: any) => {
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
        callback({ name: 'merge', value: complete, msg: "合并中" })
      }
    }).then((res) => {//这改成你的分片合并请求路径
      callback({ name: 'ok', value: res.data, msg: "完成" })
    }).catch((err) => {
      callback({ name: 'err', value: err, msg: "失败" })
    })
  })
}
// 为数组data添加索引
const add_index_for_fun = (arr: [any]) => {
  arr.map((item, index) => {
    item.index = index
    item.process_status_button = false
  })
  return arr
}

// 格式化路由数据
const format_router = (data: any) => {
  const router: any = []
  const circle = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        circle(item.children)
      } else {
        router.push({ route: item.route, component: item.component, para: item.para ? item.para : '' })
      }
    })
  }
  circle(data)
  return router
}

// 得到y-m-d日期
const formatDate = (now: any) => {
  let y
  let m
  let d
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}
// 得到h-mi-s时间
const formatDate2 = (now: any) => {
  let h
  let mi
  let s
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const formateTime = (now: any) => {
  let y
  let m
  let d
  let h
  let mi
  let s
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + ' ' + (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const slice_date = (date: string) => date.slice(0, 10)

// 去除特殊字符串
const delete_string = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\s*/g, "")
  return str.replace(/[\^\-\,\@\!\|\~\`\(\)\#\+\=\$\%\^\&\*\{\}\:\;\"\'\<\>\?]/g, '')
}

// 去除换行回车符
const delete_enter = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\ +/g, "");
  str = str.replace(/[\r\n]/g, "");
  return str
}

// 检测输入
const test_input = {
  tel: (tel: any) => {
    const t = Number(tel)
    if (isNaN(t) || t < 13000000000 || t >= 20000000000) {
      return false
    } else {
      return true
    }
  },
  filter_string: (str: string) => {
    const arr = ["'", '"', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',]
    let str_new = String(str)
    for (let i = 0; i < str_new.length; i++) {
      if (str_new[i] == ' ') {
        return true
      }
      for (let j = 0; j < arr.length; j++) {
        if (str_new[i] === arr[j]) {
          return true
        }
      }
    }
    return false
  },
  contain_chinese: (str: string) => {
    if (escape(str).indexOf("%u") < 0) { return false } else { return true }
  }
}

const find_value = (data: any, key1: string, value1: string) => {
  for (let i in data) {
    let item = data[i]
    if (item[key1] == value1) {
      return item
    }
  }
  return {}
}

const isInteger = (obj: number) => {
  return obj % 1 === 0
}

// 对比数组，找出不同。每个数组都只能是字符串数组
const match_arr = (arr_old: [any], arr_new: [any]) => {
  const add_con = []
  const delete_con = []
  for (let i in arr_old) {
    if (arr_new.indexOf(arr_old[i]) == -1) {
      delete_con.push(arr_old[i])
    }
  }
  for (let i in arr_new) {
    if (arr_old.indexOf(arr_new[i]) == -1) {
      add_con.push(arr_new[i])
    }
  }
  return { add_con, delete_con }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post_data,
  add_index_for_fun,
  format_router,
  formatDate,
  formatDate2,
  test_input,
  delete_string,
  slice_date,
  formateTime,
  find_value,
  isInteger,
  match_arr,
  delete_enter,
  uploadFile,
}