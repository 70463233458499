// 菜单组件

import { Menu } from 'antd';
import router_menu from '../../common/router_menu';
import { useState, useEffect } from 'react'
import cookie from 'react-cookies'

const { SubMenu } = Menu;

function App() {
  let [user_role, set_user_role] = useState('') // 已登录用户角色

  useEffect(() => {
    if ((cookie.load('userinfo'))) {
      set_user_role(cookie.load('userinfo')['角色'])
    }
  }, [])

  return (
    <div>
      <Menu>
        {router_menu.map((item: any) => {
          if (!item.hidden && item.range.indexOf(user_role) !== -1) {
            if (item.children) {
              return < SubMenu key={item.route} title={<span>{item.icon}<span>{item.title}</span></span>}>
                {item.children.map((item: any) => { (!item.hidden && item.range.indexOf(user_role) !== -1) && <Menu.Item key={item.route}><span><a title={item.title} href={'/#' + item.route}>{item.title}</a></span></Menu.Item> })}
              </SubMenu>
            } else {
              return <Menu.Item key={item.route}>{item.icon}<span><a title={item.title} href={'/#' + item.route}>{item.title}</a></span></Menu.Item>
            }
          }
        })}
      </Menu >
    </div >
  )
}

export default App