// 配置路由文件

import {
    ReadOutlined, FormOutlined, InsertRowBelowOutlined, HistoryOutlined, TeamOutlined, AuditOutlined
} from '@ant-design/icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default [

    {
        title: '课程管理',
        route: '/admin_department',
        icon: <AuditOutlined />,
        range: ['管理员'],
    },
    // {
    //     title: '题库管理',
    //     route: '/admin_question_bank',
    //     icon: <InsertRowBelowOutlined />,
    //     range: ['管理员'],
    // },
    // {
    //     title: '考试记录',
    //     route: '/admin_current_exam',
    //     icon: <HistoryOutlined />,
    //     range: ['管理员'],
    // },
    {
        title: '用户管理',
        route: '/admin_user',
        icon: <TeamOutlined />,
        range: ['管理员'],
    },
    // {
    //     title: '开始答题',
    //     route: '/index',
    //     icon: <FormOutlined />,
    //     range: ['答题者'],
    // },
    {
        title: '资源管理',
        route: '/course',
        icon: <ReadOutlined />,
        range: ['管理员', '答题者'],
        hidden: true,
    },
]