// 登录组件

import { useState } from 'react'
import { message, Form, Input, Button } from 'antd'
import fun from '../../common/common_fun'
import { url } from '../../common/url_config'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import login from '../../img/exam.png'

function Login() {
  let [username, setUsernamer] = useState('')
  let [password, setPassword] = useState('')
  let [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    if (isLoading) {
      return
    }
    if (username === '' || password === '') {
      alert('请正确填写账号密码！')
      return
    }
    setIsLoading(true)
    fun['post_data'](url + 'login', { phone: username, password }).then(res => {
      if (res.status) {
        message.success('登录成功')
        cookie.save('session_key', res.result['session_key'], { path: '/' })
        cookie.save('login_id', res.result['id'], { path: '/' })
        cookie.save('userinfo', res.result, { path: '/' })
        setTimeout(() => {
          window.location.href = '/#/'
          window.location.reload()
        }, 500)
      } else {
        alert('登录失败: ' + JSON.stringify(res.result))
      }
      setIsLoading(false)
    })
  }

  return (
    <div style={{ paddingTop: 80 }} className='login_root'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img style={{ width: 50, height: 50 }} src={login} alt="" />
        <span style={{ color: '#1890ff', marginLeft: 14, fontSize: 20, fontWeight: 'bold' }}>安全作业培训系统登录</span>
      </div>
      <div style={{ width: 350, margin: '30px auto' }}>
        <Form onFinish={handleSubmit} className="login-form">
          <Form.Item>
            <Input
              size="large"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="账号"
              value={username}
              onChange={e => setUsernamer(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              size="large"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="密码"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="login-form-button">{isLoading ? '登录中...' : '登录'}</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default Login