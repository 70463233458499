import { Result } from 'antd';
import cookie from 'react-cookies'
import { useState, useEffect } from 'react'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    let [user_info, set_user_info] = useState({ '角色': '', '账号': '', '部门名称': '' })

    useEffect(() => {
        if ((cookie.load('userinfo'))) {
            set_user_info(cookie.load('userinfo'))
        }
    }, [])

    return <Result
        status="404"
        title={"您好：" + (user_info['部门名称'] || '') + user_info['角色'] + " " + user_info['账号']}
        subTitle="欢迎使用安全作业培训系统"
    />
};